@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9;
    --radius: 8px;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.custom-scrollbar {
  scrollbar-width: 6px;
  padding-right: 5px;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #737a88;
  border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
.settings-scrollbar {
  scrollbar-width: 6px;
}

.settings-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.settings-scrollbar::-webkit-scrollbar-thumb {
  background-color: #e2e1e1;
  border-radius: 8px;
}

.settings-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-viewport,
.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-alpine .ag-layout-print .ag-center-cols-viewport,
.ag-theme-alpine .ag-layout-print .ag-center-cols-container,
.ag-theme-alpine-dark .ag-layout-auto-height .ag-center-cols-viewport,
.ag-theme-alpine-dark .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-alpine-dark .ag-layout-print .ag-center-cols-viewport,
.ag-theme-alpine-dark .ag-layout-print .ag-center-cols-container,
.ag-theme-alpine-auto-dark .ag-layout-auto-height .ag-center-cols-viewport,
.ag-theme-alpine-auto-dark .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-alpine-auto-dark .ag-layout-print .ag-center-cols-viewport,
.ag-theme-alpine-auto-dark .ag-layout-print .ag-center-cols-container {
  min-height: auto !important;
}

@keyframes fadeInAndJump {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  33% {
    opacity: 0.25;
    transform: translateY(-5px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-6px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-and-jump {
  animation: fadeInAndJump 0.35s ease-in-out;
}

.fade-out-bottom {
  position: relative;
  overflow: hidden;
}

.fade-out-bottom::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #383838);
}
.spinner {
  border: 4px solid #e2e1e1;
  border-top-color: #fff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 1s linear infinite;
}
.darkSpinner {
  border: 4px solid black;
  border-top-color: #fff;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.text-shadow {
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}
[data-radix-popper-content-wrapper] {
  z-index: 999 !important;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.tlui-layout__top__left {
  margin-left: 70px !important;
}

@media (min-width: 768px) {
  .tlui-layout__top__left {
    margin-top: 8px !important;
  }
}
.tlui-helper-buttons {
  height: 32px;
  visibility: hidden;
}

/* fix for scrollbar in Mac: https://github.com/ag-grid/ag-grid/issues/4830 */
.ag-body-horizontal-scroll.ag-scrollbar-invisible {
    pointer-events: all !important;
}
